<template>
    <div>
        <vs-tooltip border>
            <vs-button icon @click="onClick">🛒</vs-button>
            <template #tooltip>
                <div class="content-tooltip">
                    <span> Producto anónimo </span>
                </div>
            </template>
        </vs-tooltip>
        <vs-dialog v-model="openModal" auto-width blur @close="closeModal">
            <template #header>
                <div class="mx-4 mt-2 d-flex">
                    <h3>Ingresa la información del producto anónimo 🕵️‍♀️</h3>
                </div>
            </template>
            <div class="con-content">
                <div
                    class="d-flex flex-column justify-content-center align-items-center"
                >
                    <span>
                        {{ introduction }}
                    </span>
                    <div class="row my-5">
                        <vs-input
                            v-model="name"
                            class="col-12"
                            border
                            shadow
                            :state="stateInputDark"
                            :label="labelConcept"
                            @focus="onFocusConcept"
                        />
                        <input-money-component
                            v-model="priceOffer"
                            border
                            class="col-12 mt-5"
                            enabled
                            :label="labelValue"
                            icon="💲"
                            @focus="focus"
                        />
                        <vs-input
                            v-model="quantity"
                            border
                            shadow
                            class="mt-5 col-12"
                            :label="labelQuantity"
                            type="number"
                            :state="stateInputDark"
                            min="1"
                            @focus="focus"
                        />
                    </div>
                    <div class="row">
                        <vs-button
                            icon
                            :disabled="!priceOffer || !quantity || !name"
                            @click="pushAnonymousProduct"
                        >
                            Agregar producto anonimo ✔️
                        </vs-button>
                    </div>
                </div>
            </div>
        </vs-dialog>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import InputMoneyComponent from "@/components/utils/InputMoneyComponent.vue";

export default {
    name: "ProductAnonymousButton",
    components: {
        InputMoneyComponent
    },
    props: {
        introduction: {
            type: String,
            default:
                "Si no tienes productos en el inventario aún puedes registrar un producto anónimo. Puedes ingresar en el nameo el código de barras del producto o una descripción corta del producto.",
            required: false
        },
        labelQuantity: {
            type: String,
            default: "Cantidad",
            required: false
        },
        labelValue: {
            type: String,
            default: "Precio",
            required: false
        },
        labelConcept: {
            type: String,
            default: "Concepto",
            required: false
        }
    },
    emits: ["open", "close", "input"],
    data: () => ({
        openModal: false,
        priceOffer: 1000,
        quantity: 1,
        name: "No especifica nombre"
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor", "stateInputDark"])
    },
    methods: {
        onClick() {
            this.$emit("open");
            this.openModal = true;
        },
        closeModal() {
            this.$emit("close");
        },
        pushAnonymousProduct() {
            this.$emit("input", {
                priceOffer: this.priceOffer,
                quantity: this.quantity,
                product: {
                    name: this.name
                }
            });
            this.openModal = false;
            this.name = "No especifica nombre";
        },
        onFocusConcept() {
            if (this.name === "No especifica nombre") {
                this.name = "";
            }
            this.focus();
        },
        focus() {
            this.$emit("focus");
        }
    }
};
</script>

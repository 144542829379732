<template>
    <div class="mx-auto px-3">
        <span class="h4">
            Stock habilidatado
            <strong class="text-success">
                {{ stockActive }}
            </strong>
        </span>
        <table v-if="inventories.length" class="mt-4 table" :class="tableDark">
            <thead>
                <tr>
                    <th scope="col">
                        <strong> Comercio </strong>
                    </th>
                    <th scope="col">
                        <strong> Precio en comercio </strong>
                    </th>
                    <th scope="col">
                        <strong> Stock </strong>
                    </th>
                    <th scope="col">
                        <strong> Min stock </strong>
                    </th>
                    <th scope="col">
                        <strong> Max stock </strong>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in inventories" :key="index">
                    <td class="col-3">
                        <span class="link-warning text-capitalize">
                            <a
                                :href="`/app/${item.merchant.merchantType}s/detail/${item.merchant.merchantId}`"
                                target="_blank"
                                rel="noopener"
                            >
                                {{ item.isEnabled ? "🟢" : "⚪" }}
                                {{
                                    item.merchant.merchantType
                                        | merchantInfo("type")
                                }}
                                {{ item.merchant.name }}
                            </a>
                        </span>
                    </td>
                    <td class="col-3">
                        <div class="d-flex flex-column align-items-center">
                            <small
                                class="text-center font-italic font-weight-bold"
                            >
                                {{ item.priceOfferMerchant | money }}
                            </small>
                            <small class="text-center font-italic text-muted">
                                &nbsp; {{ item.priceMerchant | money }}
                            </small>
                        </div>
                    </td>
                    <td class="col-2">
                        <span>
                            {{ item.stock }}
                        </span>
                    </td>
                    <td class="col-2">
                        <span>
                            {{ item.minStockMerchant }}
                        </span>
                    </td>
                    <td class="col-2">
                        <span>
                            {{ item.maxStockMerchant }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-else class="mt-4">
            <strong> No hay inventario en tiendas </strong>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "InventoryByReferenceTable",
    props: {
        stockActive: {
            default: () => 0,
            type: Number,
            required: false
        },
        inventories: {
            default: () => [],
            type: Array,
            required: true
        }
    },
    data: () => ({}),
    computed: {
        ...mapGetters("control", ["tableDark"])
    },
    mounted() {},
    methods: {}
};
</script>
